








































































import Vue from "vue";
import {
  AgreementPeriodDto,
  AgreementRouteWithAgreementStatusDto,
  AgreementsClientClient,
  ApiConfiguration,
  LoadClientEditDateRequirementsDto,
  LoadClientEditDatesDto,
  LoadStatus,
  LoadValuePermission,
  LoadValuePermissionDto,
  CargoType
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { alertDialog } from "@/scripts/misc/popupDialogs";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import {
  PickupDeliveryRules,
  pickupDeliveryRules
} from "@/scripts/misc/dateUtils";

export default Vue.extend({
  props: {
    step: Number,
    dates: LoadClientEditDatesDto,
    loadStatus: Number as () => LoadStatus | undefined,
    dateRequirements: LoadClientEditDateRequirementsDto,
    agreementRoute: AgreementRouteWithAgreementStatusDto,
    valuePermissions: Array as () => LoadValuePermissionDto[],
    cargoType: Number as () => CargoType
  },
  components: {
    DatePicker,
    Checkbox,
    GreyTitle
  },
  watch: {
    agreementRoute(
      newVal?: AgreementRouteWithAgreementStatusDto,
      oldVal?: AgreementRouteWithAgreementStatusDto
    ) {
      this.fetchAgreementPeriod();
      if (
        this.agreementRoute &&
        this.loadStatus === undefined &&
        newVal?.id !== oldVal?.id
      ) {
        this.dateRequirements.bookedDelivery = this.agreementRoute.mandatoryDeliveryDateRequiredDefaultValue;
        this.dateRequirements.bookedPickup = this.agreementRoute.mandatoryPickUpDateRequiredDefaultValue;
      }
    }
  },
  computed: {
    gteToday(): boolean {
      return this.loadStatus === undefined;
    },
    dateRules(): PickupDeliveryRules {
      return pickupDeliveryRules(
        this.dates.pickupEarliest,
        this.dates.pickupLatest,
        this.dates.deliverEarliest
      );
    },
    pickupEarliestEnabled(): boolean {
      return (
        this.loadStatus === undefined ||
        this.valuePermissions.some(
          p =>
            p.permissionType === LoadValuePermission.PickupEarliest && p.enabled
        )
      );
    },
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    }
  },
  methods: {
    checkAgreementPeriod() {
      if (
        this.agreementPeriods.filter(
          p =>
            p.from <= this.dates.pickupEarliest &&
            p.to >= this.dates.pickupEarliest
        ).length === 0
      ) {
        alertDialog(
          this.$t("MissingAgreement").toString(),
          this.$t("MissingAgreementDuringDate")
            .toString()
            .replace("{date}", this.dates.pickupEarliest.datePrint()) +
            "\n\n" +
            this.agreementPeriods
              .map(p => `${p.from.datePrint()} - ${p.to.datePrint()}`)
              .join("\n")
        );
      }
    },
    fetchAgreementPeriod() {
      if (!this.agreementRoute) {
        return;
      }
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .agreementPeriods(this.agreementRoute.id)
        .then(res => {
          this.agreementPeriods = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { agreementPeriods: AgreementPeriodDto[] } => ({
    agreementPeriods: []
  })
});
